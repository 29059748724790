<template>
	<div class="app-container">
		<el-row :gutter="12">
			<el-col :xs="24" :sm="12" :md="12" :lg="6">
				<div class="sub-title">{{$t('reports.platicard.de-la')}}</div>
				<el-date-picker class="fullWidth" v-model="date.dateMin" type="date"
					:placeholder="$t('comm.service.from-ph')">
				</el-date-picker>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6">
				<div class="sub-title">{{$t('reports.platicard.pana-la')}}</div>
				<el-date-picker class="fullWidth" v-model="date.dateMax" type="date"
					:placeholder="$t('comm.service.to-ph')">
				</el-date-picker>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6">
				<el-button style="margin-top: 2.3em;" type="primary" size="mini" icon="el-icon-check" plain
					@click.native="applyFilters">{{$t('general.apply')}}</el-button>
			</el-col>
		</el-row>

		<div class="separator"></div>

		<el-card class="box-card">
			<el-table :data="monthlyPayments" border style="width: 100%">
				<el-table-column prop="month" :label="$t('reports.platicard.luna')">
				</el-table-column>
				<el-table-column prop="paymentDate" :label="$t('reports.platicard.data-platii')">
				</el-table-column>
				<el-table-column prop="toPay" :label="$t('reports.platicard.de-platit')">
				</el-table-column>
				<el-table-column prop="paid" :label="$t('reports.platicard.platit')">
				</el-table-column>
				<el-table-column prop="remainder" :label="$t('reports.platicard.rest')">
				</el-table-column>
			</el-table>
		</el-card>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		cardPayments
	} from '@/api/reports';

	import Vue from 'vue';

	import {
		currentDate,
		lastMonthDate,
		nFormatter
	} from '@/utils/index';

	export default {
		components: {
			Box
		},
		data() {
			return {
				date: {
					dateMin: '',
					dateMax: ''
				},
				monthlyPayments: [

				]
			};
		},
		methods: {
			applyFilters() {
				this.cardPayments();
			},
			cardPayments() {
				window.debug = this.date;
				// let data = {
				//     dateMin: this.date.dateMin.toJSON().slice(0, 10).replace(/-/g, '-'),
				//     dateMax: this.date.dateMax.toJSON().slice(0, 10).replace(/-/g, '-')
				// }
				if (this.date.dateMin == null || this.date.dateMin === '') {
					this.sbMsg({
						type: 'error',
						message: 'Data de inceput este obligatorie'
					});
					return false;
				}
				if (this.date.dateMax == null || this.date.dateMax === '') {
					this.sbMsg({
						type: 'error',
						message: 'Data de sfarsit este obligatorie'
					});
					return false;
				}
				const data = {
					dateMin: this.date.dateMin.toLocaleDateString('hu-HU'),
					dateMax: this.date.dateMax.toLocaleDateString('hu-HU')
				};

				data.dateMin = data.dateMin.slice(0, -1).replace(/\./g, '-').replace(/\s/g, '');
				data.dateMax = data.dateMax.slice(0, -1).replace(/\./g, '-').replace(/\s/g, '');

				cardPayments(data).then((res) => {
					for (const e in res.message.monthlyReports) {
						res.message.monthlyReports[e].month = res.message.monthlyReports[e].month.substring(0, 4) +
							' ' + res.message.monthlyReports[e].month.substring(4);
					}

					this.monthlyPayments = Object.values(res.message.monthlyReports);
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'cardPayments',
							params: data
						}
					});
					console.log(e);
				});
			},
			makeDates() {
				const lastMonth = new Date();
				lastMonth.setDate(lastMonth.getDate() - 30);
				this.date.dateMin = new Date(lastMonth.toISOString().split('T')[0].slice(0, -2) + '01');

				this.date.dateMax = new Date(new Date().toJSON().slice(0, 10).replace(/-/g, '-'));

				this.cardPayments();
			}
		},
		computed: {},
		watch: {},
		created() {
			this.makeDates();
		},
		beforeDestroy() {
			// EventBus.$off('closeModal')
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	@import './src/styles/modules/reports.scss';
</style>