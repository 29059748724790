import fetch from '@/utils/fetch';

export function getProductChartData(data) {
    return fetch({
        url: '/reports/getProductChartData',
        method: 'post',
        data: data
    });
}

export function getProductSummary(data) {
    return fetch({
        url: '/reports/getProductSummary',
        method: 'post',
        data: data
    });
}

export function compareProductsReport(data) {
    return fetch({
        url: '/reports/compareProductsReport',
        method: 'post',
        data: data
    });
}

export function getSingleProductChart(data) {
    return fetch({
        url: '/reports/getSingleProductChart',
        method: 'post',
        data: data
    });
}

export function getSingleProductSummary(data) {
    return fetch({
        url: '/reports/getSingleProductSummary',
        method: 'post',
        data: data
    });
}

export function cardPayments(data) {
    return fetch({
        url: '/reports/cardPayments',
        method: 'post',
        data: data
    });
}

export function priceDiff(data) {
    return fetch({
        url: '/reports/priceDiff',
        method: 'post',
        data: data
    });
}

export function ordersReport(data) {
    return fetch({
        url: '/reports/ordersReport',
        method: 'post',
        data: data
    });
}

export function downloadOrdersReport(data) {
    return fetch({
        url: '/reports/downloadOrdersReport',
        method: 'post',
        data: data
    });
}

export function downloadOrdersReportPDF(data) {
    return fetch({
        url: '/reports/downloadOrdersReportPDF',
        method: 'post',
        data: data
    });
}
